import React, { useEffect, useState } from 'react'
import TaskTag from './TaskTag'
import {
  Flex,
  Text,
  Image,
  Box,
  HStack,
  VStack,
  Center,
  Heading,
} from '@chakra-ui/react'
import { CheckboxAnswer, CheckboxTask } from 'data-models/task-models'
import { CheckIcon } from '@chakra-ui/icons'
import infoImage from '../../../../assets/images/information-circle.svg'
import CheckboxAnswerGroup from './CheckboxAnswerGroup/CheckboxAnswerGroup'
import { apiBase } from 'services/config'

export default function CheckboxTaskCmp(props: { task: any }) {
  const currentTask = props.task

  const [task, setTask] = useState(currentTask as CheckboxTask)

  function onSelectedImageChange(changed: string) {
    const images = task.images?.map(img => ({
      ...img,
      checked: img.url === changed ? !img.checked : img.checked,
    }))
    setTask(v => ({ ...v, ...{ images: images } }))
  }

  const onAnswerChange = (answers: CheckboxAnswer[]) => {
    setTask(v => ({ ...v, ...{ answerOptions: answers } }))
  }
  useEffect(() => {}, [task])

  return (
    <Flex direction='column' h='full' top={10} w='100%'>
      <Flex
        direction='column'
        align='stretch'
        p='5'
        h='full'
        borderRadius='md'
        bg='#773FFD'
      >
        <Box>
          <TaskTag text={task.description} />
          <Heading
            as='h1'
            fontSize='xl'
            fontWeight='semibold'
            mt='3'
            lineHeight='1'
          >
            {task.title}
          </Heading>

          <Text fontWeight='semibold' mt='3' mb='1'>
            {task.subtitle}
          </Text>
        </Box>

        {task.imageUrl && (
          <>
            <Center
              my='5'
              flex='1'
              alignSelf='center'
              bg='white'
              width='100%'
              height='100%'
              borderRadius='xl'
            >
              <Image
                src={apiBase + task.imageUrl}
                p={task.imageBorder === 'no' ? '0' : '10'}
                borderRadius='xl'
              />
            </Center>
          </>
        )}

        {task.images && (
          <>
         
           {
            (task.name==='Physics' || task.name === 'Fysik' || task.name === 'Physik') &&
            <Box backgroundColor={'white'} p='20px' borderRadius={'20px'} h='max-content' alignSelf={'center'} mt='30px' mb='30px'>
               <Image h='300px' src={apiBase + '/assets/science/glassBowl.png'}/>
            </Box>
           }
          <Center w='full' flex='1' p='5' >
          
            <Flex
              w={task.images.length <= 4 ? 'full' : '70vh'}
              wrap='wrap'
              justify='center'
            >
              {task.images?.map(img => (
                <Center
                  key={img.url}
                  // boxSize={task.images?.length === 3 ? '30%' : '20vh'}
                  boxSize = '20vh'
                  bgColor='white'
                  borderRadius='xl'
                  borderWidth='4px'
                  mx={task.images?.length === 3 ? '3' : '2'}
                  mb={
                    task.images!.length <= 4
                      ? task.images!.length === 3
                        ? '20'
                        : '10'
                      : '3'
                  }
                  mt={task.images!.length <= 4 ? '10' : '0'}
                  borderColor={img.checked ? 'pink.500' : 'transparent'}
                  pos='relative'
                  onClick={() => onSelectedImageChange(img.url)}
                >
                  {img.checked && (
                    <Center
                      pos='absolute'
                      left='1'
                      top='1'
                      boxSize='5'
                      bgColor='pink.500'
                      borderRadius='full'
                    >
                      <CheckIcon color='white' />
                    </Center>
                  )}
                  <Image
                    src={apiBase + img.url}
                    borderRadius='xl'
                    p='2'
                    maxH='100%'
                    maxW='100%'
                    cursor='pointer'
                  />
                </Center>
              ))}
            </Flex>
          </Center>
          </>
        )}

        <VStack
          pb={'6'}
          pt='1'
          w='full'
          align='stretch'
          borderTop='1px solid #352EB1'
        >
          <HStack lineHeight='1.5'>
            <Image src={infoImage} />
            <Text fontSize='sm'>{task.taskInstructions}</Text>
          </HStack>
        </VStack>
      </Flex>

      <div style={{ height: '20px', backgroundColor: 'white' }}></div>
      <Flex direction='column' p='5' borderRadius='md' bg='#773FFD'>
        <HStack
          borderBottom='1px solid #352EB1'
          pt={'10px'}
          pb='10px'
          mb='10px'
          lineHeight='1.5'
        >
          <Image src={infoImage} />
          <Text fontSize='sm'>{task.answerInstructions}</Text>
        </HStack>

        <Flex direction='column' align='center' flex='1' pt='10px'>
          <CheckboxAnswerGroup
            options={task.answerOptions}
            onChange={onAnswerChange}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
