import React from 'react'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { VStack, Text, HStack, Stack } from '@chakra-ui/react'
import { ChartContainer, LegendItemRenderer } from 'common/Chart'
import {
  IndicatorLabels,
  avg2,
  round2,
  generateChartBarName,
  IndicatorLabelsEn,
  IndicatorLabelsDe
} from '../utils'
import { useState } from 'react'
import { DashboardFilterType } from '../../DashboardFilterType'
import { useResultsPerAgeChart } from './useResultsPerAgeChart'
import { BarChartColors, IndicatorColors } from 'data-models/preschool'
import { useTaskService } from 'services/taskService'
import { useTranslation } from 'react-i18next'
import { useDisclosure } from '@chakra-ui/react'
import ChartHelpComponent from 'common/ChartDetailHelp/ChartHelpComponent'
import ChartHelpButtonComponent from 'common/ChartDetailHelp/ChartHelpButtonComponent'
import { useMunicipalityResultsPerTaskAverage } from './useMunicipalityAverage'
import { useUser } from 'providers/UserProvider'
interface ResultPerAgeChartProps {
  filter: DashboardFilterType
  helpText: string
  title: string
  helpDetailText: string | undefined
}

export const ResultsPerAgeChart = ({
  filter,
  helpText,
  title,
  helpDetailText,
}: ResultPerAgeChartProps) => {


  const { stats, loading } = useResultsPerAgeChart(filter) 

  const {municipalityAverageData, error} = useMunicipalityResultsPerTaskAverage(filter)


  const { tasks } = useTaskService()
  const { t, i18n } = useTranslation()
  const { isOpen, onToggle } = useDisclosure()



  const categories = (stats[0]?.scores || [])?.map((score, index) => ({
    key: `${score.taskId}_${score.categoryId}`,
    name: generateChartBarName({
      categoryId: score.categoryId,
      name: (tasks.find(task => task.id === score.taskId)?.name +
        ' (' +
        tasks.find(task => task.id === score.taskId)?.description +
        ') ' +
        '') as string,
    }),
    color: BarChartColors[index],
    noAnswerAverage: `${score.taskId}_noAnswerAverage`
  }))




  const bars = categories.filter(bar => bar?.name)

  

  const municipalityData = municipalityAverageData.map((stat:any) => {
    const { scores }:any = stat


    const stats = scores.reduce(
      (acc, curr) => ({
        ...acc,
        [`${curr.taskId}_${curr.categoryId}`]: (
          curr.average as number
        )?.toFixed(2),
      }),
      {},
    )

    const average: number = avg2(scores.map(score => score.average))

    return {
      ...stats,
      average: average?.toFixed(2),
      ageYear: `${stat.age} ${t('years')}`
    }
  })



  const data = stats.map((stat,index) => {
  //   let statTotalCount 
  // if(stat.totalCount != undefined){
  //    statTotalCount = stat.totalCount
  // }

  //test comment
    const { scores } = stat
    const stats = scores.reduce(
      (acc, curr) => ({
        ...acc, 
        [`${curr.taskId}_${curr.categoryId}`]: (
          curr.average as number
        )?.toFixed(2),
        [`${curr.taskId}_noAnswerAverage`]: (
          (curr.noAnswerCount/(curr.noAnswerCount+curr.answerCount))*100 as number
        )?.toFixed(2),
      }),
      {},
    )

    const average: number = avg2(scores.map(score => score.average))

    return {
      ...stats,
      average: average?.toFixed(2),
      ageYear: `${stat.age} ${t('years')}`
    }
  })

const combinedData = data.map(item => {
  const municipalityItem = municipalityData.find(m => m.ageYear === item.ageYear)
  return{
    ...item,
    municipalityAvg : municipalityItem ? municipalityItem.average : undefined
  }
})




  const [tooltip, setTooltip] = useState<string>('')
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
     
      const noAnswerAverageBar = payload.find((bar: any) => bar.dataKey === `${tooltip.split('_')[0]}_noAnswerAverage`);

      if (stats.length === 1 && payload[0].payload.average === '0.00') {
        for (const bar of payload)
          return (
            <VStack
              bg='#344352'
              p='3'
              borderRadius='md'
              alignItems='flex-start'
              color='white'
            >
              <Text fontWeight='semibold'>{label}</Text>
              <HStack key={payload[0].name}>
                <Text fontWeight='semibold'>{t('Results_Per_Task')}: </Text>
                <Text>{Math.round(bar.value)}%</Text>
              </HStack>
            </VStack>



          )
      } else {
        const childrenResult = payload.find(
          (x: { dataKey: string }) => x.dataKey === 'average' || x.dataKey === 'municipalityAvg',
        )



        
        for (const bar of payload)
          if (bar.dataKey === tooltip)
            if (bar.dataKey !== 'average' || bar.dataKey !== 'municipalityAvg') {
              return (
                <VStack
                  bg='#344352'
                  p='3'
                  borderRadius='md'
                  alignItems='flex-start'
                  color='white'
                >
                  <Text fontWeight='semibold'>{label}</Text>
                  <HStack key={payload[0].name}>
                    <Text fontWeight='semibold'>{t('Statistic_Results')} {bar.name}: </Text>
                    <Text>{Math.round(bar.value)}%</Text>
                  </HStack>

                  {
                    childrenResult.payload.municipalityAvg && (filter.areaIds.length !== 0  || filter.departmentIds.length !== 0 || filter.preschoolIds.length !== 0 ) &&
                   
                   <HStack key={childrenResult.dataKey}>
                   <Text fontWeight='semibold'>{t('ForskolaAverage_All_Tasks')}</Text>
                   <Text>{Math.round(childrenResult.value)}%</Text>
                 </HStack>
                  }

                  <HStack key={childrenResult.payload.municipalityAvg}>
                    <Text fontWeight='semibold'>{t('OrganisationAverage_All_Tasks')}</Text>
                    <Text>{Math.round(childrenResult.payload.municipalityAvg)}%</Text>
                  </HStack>

                    {/* Display the noAnswerAverage value if present */}
        {/* {noAnswerAverageBar && (
          <HStack key={noAnswerAverageBar.name}>
            <Text fontWeight='semibold'>{t('Percent_Child_Non_Participation')}: </Text>
            <Text>{round2(noAnswerAverageBar.value)}</Text>
          </HStack>
        )} */}

                  
                </VStack>
              )
            } else {
              return (
                <VStack
                  bg='#344352'
                  p='3'
                  borderRadius='md'
                  alignItems='flex-start'
                  color='white'
                >
                  <Text fontWeight='semibold'>{label}</Text>
                  <HStack key={payload[0].name}>
                    <Text fontWeight='semibold'>{bar.name}: </Text>
                    <Text>{Math.round(bar.value)}</Text>
                  </HStack>
                </VStack>
              )
            }
      }

      return null
    }
    return null
  }


  return (
    <ChartContainer title={title} helptext={helpText} loading={loading}>
      {data.length > 0 && (
        <Stack
          direction={{ base: 'column', md: 'row' }}
          justifyContent='space-between'
          alignItems='center'
        >
          <Text w={{ base: '100%', md: '80%' }} lineHeight={2}>
            {data.length > 0 ? helpText : null}
          </Text>
          <ChartHelpButtonComponent onToggle={onToggle} isOpen={isOpen} />
        </Stack>
      )}

      {helpDetailText && (
        <ChartHelpComponent helpDetailText={helpDetailText} isOpen={isOpen} />
      )}
      <br></br>
      {data.length > 0 ? (
        <div style={{display:'flex', justifyContent: 'center'}}>
        <ResponsiveContainer width='80%' height={400}>
          <ComposedChart
            data={combinedData}
            barGap='2%'
            margin={{ bottom: 55, top: 10 }}

          >
            <CartesianGrid stroke='#dee4f2' fill='#f7fcff' />
            <YAxis ticks={[0, 50, 100]} unit='%' fontSize={12}>
              <Label
                dx={-20}
                angle={-90}
                fontSize={'12px'}
                value={t('Percent_Total_Points')}
                fontWeight='bold'
                style={{
                  textAnchor: 'middle',
                  width: '100%',
                }}
              />
            </YAxis>
            <XAxis dataKey='ageYear' fontSize={12}>
              <Label
                value={t('Overview_Task')}
                position='bottom'
                fontWeight='bold'
                fontSize={'12px'}
              />
            </XAxis>
            <Tooltip
              content={<CustomTooltip />}
              formatter={value => `${value}%`}
              cursor={{ fill: '#277aff', fillOpacity: '0.1' }}
            />
            {bars.map(bar => (
              <>
              <Bar
                key={bar.key}
                stackId={bar.key}
                dataKey={bar.key}
                name={bar.name}
                fill={bar.color}
                barSize={12}
                isAnimationActive={true}
                onMouseOver={() => {
                  setTooltip(`${bar.key}`)
                }}
              />
              {/* <Bar
                  stackId={bar.key}
                  key={bar.noAnswerAverage}
                  dataKey={bar.noAnswerAverage}
                  fill={'#D3D3D3'}
                  legendType={'none'}
                  name={t('child_non_participation')}
                  isAnimationActive={true}
                  onMouseOver={() => {
                    setTooltip(`${bar.key}`)
                  }}
                /> */}
                </>

            ))}
            {/* <Line
              legendType='square'
              dataKey={t('child_non_participation')}
              stroke='#D3D3D3'
            /> */}
            {
              filter.areaIds.length === 0 && filter.departmentIds.length === 0 && filter.preschoolIds.length === 0 ?
              <Line
              type='monotone'
              name={
                i18n.language === 'se'
                ? IndicatorLabels.ChildrenResultMunicipality
                : i18n.language === 'de'
                ? IndicatorLabelsDe.ChildrenResultMunicipality
                : IndicatorLabelsEn.ChildrenResultMunicipality
              }
              dataKey={'municipalityAvg'}
              stroke={'#D198C5FF'}
              strokeWidth={3}
              // onMouseOver={() => {
              //   setTooltip('average')
              // }}
            />
            :
            <>
            <Line
            type='monotone'
            name={
              i18n.language === 'se'
              ? IndicatorLabels.ChildrenResult
              : i18n.language === 'de'
              ? IndicatorLabelsDe.ChildrenResult
              : IndicatorLabelsEn.ChildrenResult
            }
            dataKey={'average'}
            stroke={IndicatorColors.childrenResult}
            strokeWidth={3}
            // onMouseOver={() => {
            //   setTooltip('average')
            // }}
          />
           <Line
            type='monotone'
            name={
              i18n.language === 'se'
              ? IndicatorLabels.ChildrenResultMunicipality
              : i18n.language === 'de'
              ? IndicatorLabelsDe.ChildrenResultMunicipality
              : IndicatorLabelsEn.ChildrenResultMunicipality
            }
            dataKey={'municipalityAvg'}
            stroke={'#D198C5FF'}
            strokeWidth={3}
            // onMouseOver={() => {
            //   setTooltip('municipalityAvg')
            // }}
          />
            </>
            
            }
           
            <Legend
              iconSize={10}
              formatter={LegendItemRenderer}
              verticalAlign='bottom'
              wrapperStyle={{ bottom: '15px'}}
            />
          </ComposedChart>
        </ResponsiveContainer>
        </div>
      ) : (
        <div className='nodata-avaliable'>
          {t('ResultsTable_No_Data')}
        </div>
      )}
    </ChartContainer>
  )
}
