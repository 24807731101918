import React from 'react'
import { ChartContainer, LegendItemRenderer } from 'common/Chart'
import InfoMoreSection from 'common/InfoTooltip/InfoMoreSection'
import { toNumber } from 'lodash-es'
import {
  Bar,
  XAxis,
  YAxis,
  Label,
  Legend,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  ComposedChart,
  Line,
} from 'recharts'

import { VStack, HStack, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { ageColors } from '../utils'
import { DashboardFilterType } from '../../DashboardFilterType'
import { useResultsPerAgeHorizonChart } from './useResultsPerAgeHorizonChart'
import i18n from 'i18next'
const CustomTooltip = ({ active, payload }: any) => {
  const { t } = useTranslation()
  if (active && payload && payload.length) {
    const barPayload = payload[0]?.payload
    const { age } = barPayload

    return (
      <VStack
        p='3'
        bg='#344352'
        borderRadius='md'
        alignItems='flex-start'
        color='white'
      >
        <HStack key={age}>
          <Text fontWeight='semibold'>
            {age} {t('years')}
          </Text>
        </HStack>

        <HStack>
          <Text fontWeight='semibold'>
            {t('ResultsPerAgeHorizonChart_Average')}{' '}
          </Text>
          <Text>{Math.round(barPayload[`${age}_average`])}%</Text>
        </HStack>

        {/* <HStack>
          <Text fontWeight='semibold'>
            {t('ResultsPerAgeHorizonChart_participating')}{' '}
          </Text>
          <Text>
            {(
              toNumber(barPayload[`${age}_averageDifference`]) +
              toNumber(barPayload[`${age}_average`])
            ).toFixed(2)}
          </Text>
        </HStack> */}
      </VStack>
    )
  }
  return null
}

interface ResultsPerAgeHorizonChartProps {
  filter: DashboardFilterType
}

export const ResultsPerAgeHorizonChart = ({
  filter,
}: ResultsPerAgeHorizonChartProps) => {
  const { stats, loading } = useResultsPerAgeHorizonChart(filter)
  const { t } = useTranslation()

  const data = stats.map(stat => {
    const { age, average, participantAverage } = stat

    return {
      age,
      [`${stat.age}_average`]: average.toFixed(2),
      [`${stat.age}_averageDifference`]: (participantAverage - average).toFixed(
        2,
      ),
    }
  })

  const bars = data.map(({ age }) => ({
    key: age,
    color: ageColors[toNumber(age)],
  }))

  const helptext =
  i18n.language === 'se'
  ? 'Diagrammet visar ett genomsnittligt resultat för vald tidpunkt uppdelat per uppgift och ålder.'
  : i18n.language === 'de'
  ? 'Das Diagramm zeigt das durchschnittliche Ergebnis für den ausgewählten Zeitraum, aufgeteilt nach Aufgabe und Alter.'
  : 'The diagram shows the average result for the selected time divided by task and age.'


  return (
    <ChartContainer
      title={t('ResultsPerAgeHorizonChart_Results_Age')}
      helptext={helptext}
      loading={loading}
    >
      <InfoMoreSection>{data.length > 0 && helptext}</InfoMoreSection>
      {data.length > 0 ? (
      <div style={{display:'flex', justifyContent: 'center'}}>       
        <ResponsiveContainer width='80%' height={400}>
          <ComposedChart
            key={'age'}
            layout='vertical'
            data={data}
            margin={{ bottom: 50, top: 10, right: 10 }}
            barGap='10%'
            barSize={15}
            barCategoryGap='25%'
          >
            <CartesianGrid stroke='#dee4f2' fill='#f7fcff' />
            <XAxis ticks={[0, 25, 50, 75, 100]} unit='%' type='number' fontSize={12}>
              <Label
                fontWeight='bold'
                value={t('Percent_Total_Points')}
                position='bottom'
                offset={25}
                fontSize={'12px'}
              />
            </XAxis>
            <YAxis domain={[1, 7]} dataKey='age' fontSize={12}>
              <Label
                angle={-90}
                value={t('Age')}
                fontWeight='bold'
                offset={5}
                dx={15}
                position='insideLeft'
                fontSize={'12px'}
                style={{
                  textAnchor: 'middle',
                }}
              />
            </YAxis>
            <Tooltip
              content={CustomTooltip}
              formatter={value => `${value}%`}
              cursor={{ fill: 'none' }}
            />
            <Legend
              iconSize={10}
              formatter={LegendItemRenderer}
              verticalAlign='bottom'
              wrapperStyle={{
                bottom: '0',
                marginLeft: '30px',
              }}
             
            />
            {bars.map((bar, index) => (
              <>
                <Bar
                  stackId={bar.key}
                  key={`${bar.key}_average_${index}`}
                  dataKey={`${bar.key}_average`}
                  fill={bar.color}
                  name={`${bar.key} ${t('years')}`}
                  isAnimationActive={true}
                />
                {/* <Bar
                  stackId={bar.key}
                  key={`${bar.key}_averageDifference_${index}`}
                  dataKey={`${bar.key}_averageDifference`}
                  fill={'#D3D3D3'}
                  legendType={'none'}
                  name={`${bar.key} ${t('years')}`}
                  isAnimationActive={true}
                /> */}
              </>
            ))}
            {/* <Line
              legendType='square'
              dataKey={t('child_non_participation')}
              stroke='#d4d4d4'
            /> */}
          </ComposedChart>
        </ResponsiveContainer>
        </div>
      ) : (
        <div className='nodata-avaliable'>
          {t('ResultsTable_No_Data')}
        </div>
      )}
    </ChartContainer>
  )
}
