import React, {useEffect} from 'react'
import './App.css'
import { Box } from '@chakra-ui/react'
import Unauthenticated from './pages/Unauthenticated/Unauthenticated'
import Authenticated from './pages/Authenticated/Authenticated'
import { useUser } from './providers/UserProvider'
import { FilterProvider } from 'providers/FilterProvider'
import { useGA4React } from 'ga-4-react'
import { Route, Switch } from 'react-router-dom'
import ReleaseNotesHistory from './common/ReleaseNotesHistory'



export default function App() {
  const { user } = useUser()
  const analytics = useGA4React()
  useEffect(() => {
    if (analytics) {
      analytics.pageview(location.pathname)
    }
  }, [location, analytics])
  return (
    <Box bgColor='darkIndigo' h='full' w='full' overflow='auto'>
      
      <Switch>
        <Route path='/release-notes' component={ReleaseNotesHistory} />
        <Route
          path='/'
          render={() =>
            user && user.email ? (
              <FilterProvider>
                <Authenticated />
              </FilterProvider>
            ) : (
              <Unauthenticated />
            )
          }
        />
      </Switch> 
    </Box>
  )
}
