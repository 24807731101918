import React, {useEffect, useState} from 'react'
import { FormProvider } from 'react-hook-form'

import { Button, Flex } from '@chakra-ui/react'
import { Followup } from 'data-models'
import { ActionsMenu } from '../common/ActionsMenu'
import { DeleteButton } from '../common/DeleteButton'
import { FollowupDetails } from './FollowupDetails'
import { useFollowupEditor } from './hooks'
import { SimpleActionButton } from '../common/SimpleActionButton'
import { DetailsWrapper } from '../common/EntityViewer.layout'
import { useTranslation } from 'react-i18next'

interface FollowupEditorProps {
  isCreate: boolean
  followup: Followup
  isEditEndDate?: boolean
}

export const FollowupEditor = ({ isCreate, followup,isEditEndDate }: FollowupEditorProps) => {
  const {
    isAdmin,
    formMethods,
    isEditable,
    handleCopy,
    handleCreateActive,
    handleCreateDraft,
    isCreateLoading,
    handleDelete,
    isDeleteLoading,
    handleEdit,
    isEditLoading,
    isDraft,
    handleActivateFollowup,
  } = useFollowupEditor({
    initialFollowup: followup,
  })
  const { t } = useTranslation()

  return (
    <Flex w='full' h='fit-content' bg='white'>
      <FormProvider {...formMethods}>
        <form style={{ width: '100%' }} noValidate={true}>
          <ActionsMenu
            name={followup?.name || `${t('MainHeader_FollowUp')}`}
          >
            {isAdmin && (
              <>
                {isCreate && (
                  <>
                    <Button
                      onClick={handleCreateDraft}
                      isLoading={isCreateLoading}
                      variant='outline'
                    >
                      {/* Create draft followup*/}
                      {t('Create_As_Draft')}
                    </Button>
                    <SimpleActionButton
                      onClick={handleCreateActive}
                      isLoading={isCreateLoading}
                      
                    >
                      {/* Create active followup*/}
                      {t('FollowupFilter_Create')}
                    </SimpleActionButton>
                  </>
                )}
                {!isCreate && (
                  <>
                    <DeleteButton
                      entityName={t('follow-up')}
                      onDelete={handleDelete}
                      isLoading={isDeleteLoading}
                    />
                    <SimpleActionButton onClick={handleCopy}>
                      {/* Copy */}
                      {t('Copy')}
                    </SimpleActionButton>
                  </>
                )}
                {(!isCreate && isEditable) || isEditEndDate? (
                  <>
                    {isDraft && (
                      <SimpleActionButton
                        onClick={handleActivateFollowup}
                        isLoading={isEditLoading}
                      >
                        {/* Activate a draft followup */}
                        {t('Enable_FollowUp')}
                      </SimpleActionButton>
                    )}
                    <SimpleActionButton
                      onClick={handleEdit}
                      isLoading={isEditLoading}
                    >
                      {/* Edit */}
                      {t('Dashboardfilter_Update')}
                    </SimpleActionButton>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </ActionsMenu>

          <DetailsWrapper>
            <FollowupDetails
              isEditable={(isEditable || isCreate) && isAdmin}
              followup={followup}
              isEditEndDate={isEditEndDate}
            />
          </DetailsWrapper>
        </form>
      </FormProvider>
    </Flex>
  )
}
