import { UseToastOptions } from '@chakra-ui/react'

export const successToast = (msg: string): UseToastOptions => ({
  status: 'success',
  title: msg,
  position: 'bottom-right',
})

export const errorToast = (msg: string): UseToastOptions => ({
  status: 'error',
  title: msg,
  position: 'bottom-right',
})

export const jsonCopy = (x: any) => JSON.parse(JSON.stringify(x))

export const secondsToMMSS = (x: number = 0) =>
  new Date(x * 1000).toISOString().substr(14, 5)

export const checkboxAnswerOption = {
  ChildParticipate: 'childParticipate',
}

export const calculateAcademicYear = (date: Date) => {
  if (!date) return ''; // Return a default value if date is null
  const year = date.getMonth() < 9 ? date.getFullYear() : date.getFullYear() + 1
  const lastyear =
    date.getMonth() < 9 ? date.getFullYear() - 1 : date.getFullYear()
  let academicYear = ''
  academicYear = lastyear.toString().slice(-2) + '/' + year.toString().slice(-2)
  return academicYear
}

// export const calculateAcademicYearBasedOnStartDate = (date: Date) => {
//   const year = date.getMonth() < 9 ? date.getFullYear() - 1 : date.getFullYear()
//   const lastYear =
//     date.getMonth() < 9 ? date.getFullYear() : date.getFullYear() + 1
//   let academicYear = ''
//   academicYear = year.toString().slice(-2) + '/' + lastYear.toString().slice(-2)
//   return academicYear
// }



// from august 2024 - june 2025 it should 24/25 and from july 2025 to june 2026 should dbe 25/26

export const calculateAcademicYearBasedOnStartDate = (date: Date) => {
  const month = date.getMonth();
  const year = date.getFullYear();
  let academicYear = '';

  if (month >= 7 && month <= 11) { // August to December
    academicYear = `${year.toString().slice(-2)}/${(year + 1).toString().slice(-2)}`;
  } else { // January to July
    academicYear = `${(year - 1).toString().slice(-2)}/${year.toString().slice(-2)}`;
  }

  return academicYear;
}
export const decapitalizeFirstLetter = (val: string) =>
  val.slice(0, 1).toLowerCase().concat(val.slice(1))
