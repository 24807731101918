import React from 'react'
import {
  Bar,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Text as RechartsText,
  Line,
  ComposedChart,
} from 'recharts'
import { HStack, VStack, Text, useDisclosure, Stack } from '@chakra-ui/react'
import { ChartContainer, LegendItemRenderer } from 'common/Chart'
import {
  generateChartBarName,
  Indicator,
  IndicatorColors,
  IndicatorLabels,
  IndicatorLabelsEn,
  IndicatorLabelsDe
} from '../utils'
import { useState } from 'react'
import { DashboardFilterType } from '../../DashboardFilterType'
import { useResultsPerGenderChart } from './useResultsPerGenderChart'
import { useTaskService } from 'services/taskService'
import { useTranslation } from 'react-i18next'
import ChartHelpButtonComponent from 'common/ChartDetailHelp/ChartHelpButtonComponent'
import ChartHelpComponent from 'common/ChartDetailHelp/ChartHelpComponent'
import { useMunicipalityGenderAverage } from './useMunicipalityAverage'

const CustomXAxisIntervalTickLabel = (props: any) => {
  const {
    x,
    y,
    payload: { value },
  } = props

  return (
    <RechartsText
      x={x - 20}
      y={y + 15}
      width={130}
      lineHeight={17}
      textAnchor='start'
      verticalAnchor='start'
      fontSize={12}
    >
      {value}
    </RechartsText>
  )
}

interface ResultsPerGenderChartProps {
  filter: DashboardFilterType
  helpText: string
  helpDetailText: string | undefined
}

export const ResultsPerGenderChart = ({
  filter,
  helpText,
  helpDetailText,
}: ResultsPerGenderChartProps) => {
  const genders = [Indicator.POJKE, Indicator.FLICKA]
  const { stats, loading } = useResultsPerGenderChart(filter)
  
  const { municipalityAverageData, error } =
    useMunicipalityGenderAverage(filter)

  const { tasks } = useTaskService()
  const { t, i18n } = useTranslation()
  const { isOpen, onToggle } = useDisclosure()

  const data = stats.map(stat => {
    const average = ((stat.male + stat.female) / 2).toFixed(2)
    return {
      [Indicator.POJKE]: stat.male?.toFixed(2),
      [Indicator.FLICKA]: stat.female?.toFixed(2),
      task: generateChartBarName({
        categoryId: stat.categoryId,
        name: (tasks.find(task => task.id === stat.taskId)?.name +
          '\n' +
          '(' +
          tasks.find(task => task.id === stat.taskId)?.description +
          ')') as string,
      }),
      average,
    }
  })

  const municipalityData = municipalityAverageData.map((stat: any) => {
    const average = ((stat.male + stat.female) / 2).toFixed(2)
    return {
      [Indicator.POJKE]: stat.male?.toFixed(2),
      [Indicator.FLICKA]: stat.female?.toFixed(2),
      average,
    }
  })

  const combinedData = data.map((item, index) => {
    const municipalityItem = municipalityData[index]

    if (municipalityItem) {
      const combinedItem = {
        ...item,
        municipalityAvg: municipalityItem.average,
      }
      return combinedItem
    } else {
      // Handle the case where municipalityItem is undefined
      return item // Or you can modify it as per your logic
    }
  })

  const [tooltip, setTooltip] = useState<string>('')
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const hasMunicipalityData = filter.areaIds.length === 0 &&
                                   filter.departmentIds.length === 0 &&
                                   filter.preschoolIds.length === 0;
  
      return (
        <VStack
          p="3"
          color="white"
          bg="#344352"
          borderRadius="md"
          alignItems="flex-start"
        >
          <Text fontWeight="semibold">{label}</Text>
          {payload.map((bar, index) => {
            if (bar.dataKey === tooltip ) {
              return (
                <HStack key={index}>
                  <Text fontWeight="semibold">{bar.name}:</Text>
                  <Text>{Math.round(bar.value)}%</Text>
                </HStack>
              );
            }
            if (bar.dataKey === 'average' && tooltip === 'average') {
              return (
                <HStack key={`average-${index}`}>
                  <Text fontWeight="semibold">{t('Average_Preschool_Area')}</Text>
                  <Text>{Math.round(bar.value)}%</Text>
                </HStack>
              );
            }
            if (hasMunicipalityData && bar.dataKey === 'municipalityAvg' && tooltip === 'municipalityAvg' ) {
              return (
                <HStack key={`municipalityAvg-${index}`}>
                  <Text fontWeight="semibold">{t('Average_Municipality')}</Text>
                  <Text>{Math.round(bar.value)}%</Text>
                </HStack>
              );
            }
            return null;
          })}
        </VStack>
      );
    }
    return null;
  };
  
  return (
    <ChartContainer
      title={t('Results_By_Gender')}
      helptext={helpText}
      loading={loading}
    >
      {data.length > 0 && (
        <Stack
          direction={{ base: 'column', md: 'row' }}
          justifyContent='space-between'
          alignItems='center'
        >
          <Text w={{ base: '100%', md: '80%' }} lineHeight={2}>
            {data.length > 0 ? helpText : null}
          </Text>
          <ChartHelpButtonComponent onToggle={onToggle} isOpen={isOpen} />
        </Stack>
      )}
      {helpDetailText && (
        <ChartHelpComponent helpDetailText={helpDetailText} isOpen={isOpen} />
      )}
      <br></br>
      {data.length > 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ResponsiveContainer width='80%' height={400}>
            <ComposedChart
              data={combinedData}
              barGap='4%'
              barCategoryGap='30%'
              margin={{ bottom: 90, top: 10 }}
            >
              <CartesianGrid stroke='#dee4f2' fill='#f7fcff' />
              <YAxis
                ticks={[0, 50, 100]}
                unit='%'
                minTickGap={50}
                fontSize={12}
              >
                <Label
                  dx={-20}
                  angle={-90}
                  fontSize={'12px'}
                  value={t('Percent_Total_Points')}
                  fontWeight='bold'
                  style={{
                    textAnchor: 'middle',
                    width: '100%',
                  }}
                />
              </YAxis>
              <XAxis
                dataKey='task'
                interval={0}
                fontSize={'12px'}
                height={80}
                minTickGap={30}
                tick={<CustomXAxisIntervalTickLabel />}
              >
                <Label
                  value={t('Overview_Task')}
                  position='bottom'
                  fontWeight='bold'
                  offset={50}
                  fontSize={12}
                />
              </XAxis>
              <Tooltip
                content={CustomTooltip}
                formatter={value => `${value}%`}
                cursor={{ fill: '#277aff', fillOpacity: '0.1' }}
              />
              <Legend
                iconSize={10}
                formatter={LegendItemRenderer}
                verticalAlign='bottom'
                wrapperStyle={{
                  bottom: '0px',
                  marginLeft: '35px',
                }}
              />
              {genders.map(gender => (
                <Bar
                  key={gender}
                  dataKey={gender}
                  fill={IndicatorColors[gender]}
                  name={
                    i18n.language === 'se'
                    ? IndicatorLabels[gender]
                    : i18n.language === 'de'
                    ? IndicatorLabelsDe[gender]
                    : IndicatorLabelsEn[gender]
                  }
                  stroke={
                    i18n.language === 'en'
                      ? IndicatorLabelsEn[gender]
                      : i18n.language === 'en'
                  }
                  barSize={12}
                  onMouseOver={() => {
                    setTooltip(gender)
                  }}
                />
              ))}
              {/* <Line
                legendType='square'
                dataKey={t('child_non_participation')}
                stroke='#d4d4d4'
              /> */}
              {filter.areaIds.length === 0 &&
              filter.departmentIds.length === 0 &&
              filter.preschoolIds.length === 0 ? (
                <Line
                  type='monotone'
                  name={
                    i18n.language === 'se'
                    ? IndicatorLabels.GenderMunicipality
                    : i18n.language === 'de'
                      ? IndicatorLabelsDe.GenderMunicipality
                      : IndicatorLabelsEn.GenderMunicipality
                  }
                  dataKey='municipalityAvg'
                  stroke={'#D198C5FF'}
                  strokeWidth={3}
                  onMouseOver={() => {
                    setTooltip('municipalityAvg')
                  }}
                />
              ) : (
                <>
                  <Line
                    type='monotone'
                    name={
                      i18n.language === 'se'
                        ? IndicatorLabels.GenderMunicipality
                        : i18n.language === 'de'
                        ? IndicatorLabelsDe.GenderMunicipality
                        : IndicatorLabelsEn.GenderMunicipality
                    }
                    dataKey='municipalityAvg'
                    stroke={'#D198C5FF'}
                    strokeWidth={3}
                    onMouseOver={() => {
                      setTooltip('municipalityAvg')
                    }}
                  />
                  <Line
                    type='monotone'
                    name={
                      i18n.language === 'se'
                        ? IndicatorLabels.Gender
                        : i18n.language === 'de'
                        ? IndicatorLabelsDe.Gender
                        : IndicatorLabelsEn.Gender
                    }
                    dataKey='average'
                    stroke={IndicatorColors.ChildrenResult}
                    strokeWidth={3}
                    onMouseOver={() => {
                      setTooltip('average')
                    }}
                  />
                
                </>
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div className='nodata-avaliable'>{t('ResultsTable_No_Data')}</div>
      )}
    </ChartContainer>
  )
}
