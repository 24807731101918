import { AveragePerAgeHorizonStatistics } from 'data-models/preschool'
import { useUser } from 'providers/UserProvider'
import { useEffect, useState } from 'react'
import { useAsync } from 'react-use'
import { getAveragePerAgeHorizonStatistics } from 'services/followupResultService'
import { DashboardFilterType } from '../../DashboardFilterType'
import { useFollowup } from 'services/followupService'

export const useResultsPerAgeHorizonChart = (filter: DashboardFilterType) => {
  const [stats, setStats] = useState<AveragePerAgeHorizonStatistics[]>([])

  const {
    user: { organizationId },
  } = useUser()

  // Get followup data to check whether there are submissions
  const { data: followup } = useFollowup({ 
    organizationId, 
    followupId: filter.followupId 
  })


  // const response = useAsync(
  //   () =>
  //     getAveragePerAgeHorizonStatistics(
  //       organizationId,
  //       filter.followupId,
  //       filter.areaIds,
  //       filter.preschoolIds,
  //       filter.departmentIds
  //     ),
  //   [filter],
  // )

    // Check if there are submissions and get the data or return empty array
    const response = useAsync(
      async () => {
        // Return empty array if no submissions or followup doesn't exist
        if (!filter.followupId || !followup?.numberOfSubmissions) {
          return []
        }
        return getAveragePerAgeHorizonStatistics(
          organizationId,
          filter.followupId,
          filter.areaIds,
          filter.preschoolIds,
          filter.departmentIds
        )
      },
      [filter, followup],
    )
  


  const { value, loading } = response

  useEffect(() => {
    setStats(value ? value : [])
  }, [filter, value])

  return { stats, loading }
}
