import React from 'react'
import { Box, Button, Grid, GridItem, HStack } from '@chakra-ui/react'
import { StatsContainer } from 'pages/SuperAdminSettings/common'
import { UserRole } from 'data-models'
import { useUser } from 'providers/UserProvider'
import { useTranslation } from 'react-i18next'

interface AreaStatsProps {
  loading?: boolean
  numberOfAreas?: number
  handleOpenCreateArea: () => void
}

export const AreaStats = ({
  loading = false,
  numberOfAreas = 0,
  handleOpenCreateArea,
}: AreaStatsProps) => {
  const { user } = useUser()

  const isAdmin = [UserRole.ADMIN, UserRole.SUPERADMIN].includes(user.role)
  const { t, i18n } = useTranslation()

  return (
    <Box w='full' style={{ fontFamily: 'Open Sans' }}>
      <HStack justifyContent={'space-between'} w='full'>
        <Grid w='full' templateColumns='repeat(auto-fill, max(240px))' gap={6}>
          <GridItem w='100%'>
            <StatsContainer
              name={i18n.language === 'se' ? 'Antal område' : i18n.language === 'de' ? 'Nummernkreis' : 'Number of areas' }
              count={numberOfAreas}
              loading={loading}
              imageSrc={'/images/orangeIcon.png'}
            />
          </GridItem>
        </Grid>
        {isAdmin && (
          <Button
            mb='2'
            minW='200px'
            height='35px'
            fontSize={'sm'}
            onClick={handleOpenCreateArea}
          >
            {t('Create_Area')}
          </Button>
        )}
      </HStack>
    </Box>
  )
}
