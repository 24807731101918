import React from 'react'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { VStack, Text, HStack, Stack } from '@chakra-ui/react'
import { ChartContainer, LegendItemRenderer } from 'common/Chart'
import {
  IndicatorLabels,
  avg2,
  round2,
  generateChartBarName,
  IndicatorLabelsEn,
  IndicatorLabelsDe
} from '../utils'
import { useState } from 'react'
import { LanguageAnalysisType } from '../../LanguageAnalysisType'
import { useNumberOfWords } from './useNumberOfWords'
import { BarChartColors, IndicatorColors, LanguageAnalysisStatisticsType } from 'data-models/preschool'
import { useTaskService } from 'services/taskService'
import { useTranslation } from 'react-i18next'
import { useDisclosure } from '@chakra-ui/react'
import ChartHelpComponent from 'common/ChartDetailHelp/ChartHelpComponent'
import ChartHelpButtonComponent from 'common/ChartDetailHelp/ChartHelpButtonComponent'
interface NumberOfWordsChartProps {
  filter: LanguageAnalysisType
  helpText: string
  title: string
  stats: LanguageAnalysisStatisticsType[]
  loading: boolean
}

export const NumberOfWordsChart = ({
  filter,
  helpText,
  title,
  stats,
  loading
}: NumberOfWordsChartProps) => {
  // const { stats, loading } = useNumberOfWords(filter)

  const { tasks } = useTaskService()
  const { t, i18n } = useTranslation()
  const { isOpen, onToggle } = useDisclosure()

  // const categories = (stats[0]?.tasks || [])?.map((taskArr, index) => ({
  //   key: `${taskArr.taskId}`,
  //   name: generateChartBarName({
  //     categoryId: 'LanguageAnalysis',
  //     name: (tasks.find(task => task.id === taskArr.taskId)?.name +
  //       ' (' +
  //       tasks.find(task => task.id === taskArr.taskId)?.description +
  //       ') ' +
  //       '') as string,
  //   }),
  //   color: BarChartColors[index],
  // }))

  const relevantTaskIds = ['7ff9f536-d816-4a3c-af57-ff63b1ae97c0', '632fc3d0-9289-4a59-a7f3-d2c2ae42cce4'];

  const categories = relevantTaskIds.map((taskId, index) => {
    const task = tasks.find(task => task.id === taskId);
    return {
      key: taskId,
      name: generateChartBarName({
        categoryId: 'LanguageAnalysis',
        name: (task?.name + ' (' + task?.description + ') ') as string,
      }),
      color: BarChartColors[index % BarChartColors.length], // Ensure color index is within bounds
    };
  });

  const bars = categories.filter(bar => bar?.name)

  

  const data = stats.map(stat => {
    const { tasks } = stat
    const stats = tasks.reduce(
      (acc, curr) => ({
        ...acc,
        [`${curr.taskId}`]: (
          curr.totalWords as number
        )?.toFixed(2),
      }),
      {},
    )

    //const average: number = avg2(scores.map(score => score.average))

    return {
      ...stats,
      
      // average: average?.toFixed(2),
      ageYear: `${stat.age} ${t('years')}`,
    }
  })

  const [tooltip, setTooltip] = useState<string>('')


  const maxWords = Math.max(...stats.flatMap(stat => stat.tasks.map(task => task.totalWords))) + 50;
  const maxTick = Math.ceil(maxWords / 50) * 50;
  
  // Explicitly declare yTicks as an array of numbers
  const yTicks: number[] = [];
  for (let i = 0; i <= maxTick; i += 50) {
    yTicks.push(i);
  }
  
  const CustomTooltip = ({ active, payload, label }: any) => {

    if (active && payload && payload.length) {
      if (stats.length === 1 && payload[0].payload.average === '0.00') {
        for (const bar of payload)
          return (
            <VStack
              bg='#344352'
              p='3'
              borderRadius='md'
              alignItems='flex-start'
              color='white'
            >
              <Text fontWeight='semibold'>{label}</Text>
              <HStack key={payload[0].name}>
                <Text fontWeight='semibold'>{t('Number_Of_Words')}: </Text>
                <Text>{round2(bar.value)}</Text>
              </HStack>
            </VStack>
          )
      } else {
        const childrenResult = payload.find(
          (x: { dataKey: string }) => x.dataKey === 'average',
        )
        for (const bar of payload)
          if (bar.dataKey === tooltip)
            if (bar.dataKey !== 'average') {
              return (
                <VStack
                  bg='#344352'
                  p='3'
                  borderRadius='md'
                  alignItems='flex-start'
                  color='white'
                >
                  <Text fontWeight='semibold'>{label}</Text>
                  <Text fontWeight='semibold'>{bar.name}</Text>
                  <HStack key={payload[0].name}>
                    <Text fontWeight='semibold'>{t('Number_Of_Words')}: </Text>
                    <Text>{round2(bar.value)}</Text>
                  </HStack>

                  {/* <HStack key={childrenResult.dataKey}>
                    <Text fontWeight='semibold'>{childrenResult.name}: </Text>
                    <Text>{round2(childrenResult.value)}</Text>
                  </HStack> */}
                </VStack>
              )
            } else {
              return (
                <VStack
                  bg='#344352'
                  p='3'
                  borderRadius='md'
                  alignItems='flex-start'
                  color='white'
                >
                  <Text fontWeight='semibold'>{label}</Text>
                  <HStack key={payload[0].name}>
                    <Text fontWeight='semibold'>{bar.name}: </Text>
                    <Text>{round2(bar.value)}</Text>
                  </HStack>
                </VStack>
              )
            }
      }

      return null
    }
    return null
  }
  return (
    <ChartContainer title={title} helptext={helpText} loading={loading}>
      {data.length > 0 && (
        <Stack
          direction={{ base: 'column', md: 'row' }}
          justifyContent='space-between'
          alignItems='center'
        >
          <Text w={{ base: '100%', md: '80%' }} lineHeight={2}>
            {data.length > 0 ? helpText : null}
          </Text>
        </Stack>
      )}

      <br></br>
      {data.length > 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ResponsiveContainer width='80%' height={400}>
            <ComposedChart
              data={data}
              barGap='2%'
              margin={{ bottom: 55, top: 10 }}
            >
              <CartesianGrid stroke='#dee4f2' fill='#f7fcff' />
              <YAxis ticks={yTicks} fontSize={12}>
                <Label
                  dx={-20}
                  angle={-90}
                  fontSize={'12px'}
                  value={t('Number_Of_Words')}
                  fontWeight='bold'
                  style={{
                    textAnchor: 'middle',
                    width: '100%',
                  }}
                />
              </YAxis>
              <XAxis dataKey='ageYear' fontSize={12}>
                <Label position='bottom' fontWeight='bold' fontSize={'12px'} />
              </XAxis>
              <Tooltip
                content={<CustomTooltip />}
                formatter={value => `${value}%`}
                cursor={{ fill: '#277aff', fillOpacity: '0.1' }}
              />
              {bars.map(bar => (
                <Bar
                  key={bar.key}
                  dataKey={bar.key}
                  name={bar.name}
                  fill={bar.color}
                  barSize={12}
                  isAnimationActive={true}
                  onMouseOver={() => {
                    setTooltip(`${bar.key}`)
                  }}
                />
              ))}

              {/* <Line
                type='monotone'
                name={
                  i18n.language === 'se'
                    ? IndicatorLabels.ChildrenResult
                    : i18n.language === 'de'
                    ? IndicatorLabelsDe.ChildrenResult
                    : IndicatorLabelsEn.ChildrenResult
                }
                dataKey={'average'}
                stroke={IndicatorColors.childrenResult}
                strokeWidth={3}
                onMouseOver={() => {
                  setTooltip('average')
                }}
              /> */}
              <Legend
                iconSize={10}
                formatter={LegendItemRenderer}
                verticalAlign='bottom'
                wrapperStyle={{ bottom: '15px' }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div className='nodata-avaliable'>{t('ResultsTable_No_Data')}</div>
      )}
    </ChartContainer>
  )
}
