import React from 'react'
import { defaultFollowup } from 'data-models/followup'
import { YRFilter } from './YearlyFilterType'
import { NumberOfWordsChart }from '../Charts/NumberOfWordsChart'
import { ChartWrapper } from '../components'
import { LanguageAnalysisType } from '../../LanguageAnalysisType'
import { VStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { SpeechRateChart } from '../Charts/SpeechRateChart'
import { useNumberOfWords } from '../Charts/useNumberOfWords'
import { SpeakingTimeChart } from '../Charts/SpeakingTimeChart'

export const defaultYRFilter: YRFilter = {
  assignmentTypes: [],
  followups: defaultFollowup,
  areas: [],
  preschools: [],
  ages: [],
}

interface YearlyResultProps {
  filter: LanguageAnalysisType;
}

export const YearlyResult = ({ filter }: YearlyResultProps) => {
  const { t } = useTranslation()
  const { stats, loading } = useNumberOfWords(filter)


  return (
    <VStack spacing='6' marginTop='6'>
      <ChartWrapper> 
        <NumberOfWordsChart
          filter={filter}
          title={t('Number_Of_Words')}
          helpText={t('Number_Of_Words_HelpText')}
          stats = {stats}
          loading = {loading}/>
      </ChartWrapper>  
      <ChartWrapper>
      <SpeechRateChart
           filter={filter}
           title={t('Speech_Rate')}
           helpText={t('Speech_Rate_HelpText')}
           stats = {stats}
           loading = {loading}/>
      </ChartWrapper>
      <ChartWrapper>
      <SpeakingTimeChart
           filter={filter}
           title={t('Speaking_Time')}
           helpText={t('Speaking_Time_HelpText')}
           stats = {stats}
           loading = {loading}/>
      </ChartWrapper>
    </VStack>
  )
}
