import React, { useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  Label,
  Line,
  ComposedChart,
} from 'recharts'

import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent'

import { TooltipProps } from 'recharts'
import { useTaskService } from 'services/taskService'
import { Box, HStack, VStack, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

// Custom tooltip function to show taskName instead of taskId and other details

function ChildNonParticipationPerAge({ stats }) {
  // Prepare data for the bar chart

  const { t } = useTranslation()


 

  function CustomLegend() {
    return (
      <VStack align='center' justifyContent='center' spacing={2} mb={4}>
      <HStack align='center' justifyContent='center' spacing={2}>
        <Box display='flex' alignItems='center'>
          <Box w='10px' h='7.5px' bg='#FF80AB' mr={2} />
          <span
            style={{
              fontSize: '12px',
              color: 'rgb(65, 86, 126)',
              lineHeight: '25px',
            }}
          >
            {t('Child_Distracted_Legend')}
          </span>
        </Box>
        <Box display='flex' alignItems='center'>
          <Box w='10px' h='7.5px' bg='#EA80FC' mr={2} />
          <span
            style={{
              fontSize: '12px',
              color: 'rgb(65, 86, 126)',
              lineHeight: '25px',
            }}
          >
            {t('Child_Understand_Legend')}
          </span>
        </Box>
      </HStack>
    
      <Box display='flex' alignItems='center'>
        <Box w='10px' h='7.5px' bg='#B388FF' mr={2} />
        <span
          style={{
            fontSize: '12px',
            color: 'rgb(65, 86, 126)',
            lineHeight: '25px',
          }}
        >
          {t('Child_Foreign_Legend')}
        </span>
      </Box>
    </VStack>
    
    )
  }

  const [dataKeyTask, setDataKeyTask] = useState('')



  // Prepare data for the bar chart
const dataSample1 = stats.map(stat => ({
  ageYear: `${stat.age} years`, // Age formatting
  distractedCount: stat.distractedCount,
  understandCount: stat.understandCount,
  foreignCount: stat.foreignCount,
  // maxNoAnswerCount: Math.max(...stat.scores.map(score => score.noAnswerCount)), // Find max noAnswerCount from scores
  maxNoAnswerCount: stat.nonParticipationReasonCount

}))

const filteredDataSample = dataSample1.filter(
  item =>
    item.distractedCount !== 0 ||
    item.understandCount !== 0 ||
    item.foreignCount !== 0 ||
    item.maxNoAnswerCount !== 0 
)
  const generateEqualIntervals = (maxValue, intervalCount = 5) => {
    const step = Math.ceil(maxValue / (intervalCount - 1)) // Calculate the interval step
    const ticks: number[] = []
    for (let i = 0; i < intervalCount; i++) {
      ticks.push(i * step)
    }
    return ticks
  }
  // Finding the maximum value from all counts
  const maxCount = Math.max(
    ...dataSample1.flatMap(item => [
      item.distractedCount,
      item.understandCount,
      item.foreignCount,
      item.maxNoAnswerCount
    ]),
  )

  // Calculate equal intervals for Y-axis ticks
  const yAxisTicks = generateEqualIntervals(maxCount + 2) // Add 2 for buffer

  const CustomTooltip = ({
    active,
    payload,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      const { ageYear, distractedCount, understandCount, foreignCount ,maxNoAnswerCount} =
        payload[0].payload




        const totalCount = Math.max(distractedCount, understandCount, foreignCount)

      return (
        <VStack
          bg='#344352'
          p='3'
          borderRadius='md'
          alignItems='flex-start'
          color='white'
        >
          <p style={{ fontWeight: 'bold' }}>{`${ageYear}`}</p>
          <p>
            <span style={{ fontWeight: 'bold' }}>
              {t('Total_Not_Participate')}
            </span>
            &nbsp;&nbsp;{maxNoAnswerCount}
          </p>
          {dataKeyTask === 'distractedCount' && (
            <p>
              <span style={{ fontWeight: 'bold' }}>
                {t('Child_Distracted_Tooltip')}:
              </span>
              &nbsp;&nbsp;{distractedCount}
            </p>
          )}

          {dataKeyTask === 'understandCount' && (
            <p>
              <span style={{ fontWeight: 'bold' }}>
                {t('Child_Understand_Tooltip')}:
              </span>
              &nbsp;&nbsp;{understandCount}
            </p>
          )}
          {dataKeyTask === 'foreignCount' && (
            <p>
              <span style={{ fontWeight: 'bold' }}>
                {t('Child_Foreign_Tooltip')}:
              </span>
              &nbsp;&nbsp;{foreignCount}
            </p>
          )}
        </VStack>
      )
    }
    return null
  }

  // const dataSample1 = stats.map(stat => {
  //   const { scores } = stat
  //   const statsTransformed = scores.reduce((acc, curr) => {
  //     return {
  //       ...acc,
  //       distractedCount: (acc.distractedCount || 0) + curr.distractedCount,
  //       foreignCount: (acc.foreignCount || 0) + curr.foreignCount,
  //       understandCount: (acc.understandCount || 0) + curr.understandCount,
  //     }
  //   }, {})

  //   return {
  //     ageYear: `${stat.age} years`, // Age formatting
  //     ...statsTransformed,
  //   }
  // })

  // const filteredDataSample = dataSample1.filter(
  //   item =>
  //     item.distractedCount !== 0 ||
  //     item.understandCount !== 0 ||
  //     item.foreignCount !== 0,
  // )


  return (
    <Box width='50%'>
      {/* <Text w={{ base: '100%', md: '100%' }} lineHeight={2} mb='10' mt='5'>
        {t('CDP_Help')}
      </Text> */}
<Text
      fontSize="15px"
      fontWeight="bold"
      textAlign="center"
      mb={4}
    >
   {t('Total_Non_Participate')}
    </Text>
      <ResponsiveContainer width='100%' height={420} >
        <ComposedChart data={filteredDataSample}>
        {/* <BarChart data={filteredDataSample}> */}
          <CartesianGrid stroke='#dee4f2' fill='#f7fcff' />

          {/* <XAxis dataKey="ageYear" interval={0} tickFormatter={(tick: any) => String(tick)} /> */}
          <XAxis
            dataKey='ageYear'
            fontSize={12}
             tickFormatter={tick => `${tick.split('')[0]} ${t('years')}`}
          ></XAxis>

          <YAxis
            ticks={yAxisTicks}
            domain={[0, yAxisTicks[yAxisTicks.length - 1]]} // Set the domain based on max tick value
            fontSize={12}
          >
            {' '}
            {/* Y-Axis with max value + 10 */}
            <Label
              angle={-90}
              width={350}
              fontSize={'12px'}
              value={t('Num_Children_CDP')}
              textBreakAll={true}
              position='insideLeft'
              fontWeight='bold'
              style={{
                textAnchor: 'middle',
              }}
              dx={20}
            />
          </YAxis>

          <Tooltip
            content={<CustomTooltip />}
            wrapperStyle={{ backgroundColor: 'transparent', border: 'none' }}
            cursor={{ fill: 'transparent' }} // Removes gray hover background
          />

          {/* Separate bars for each task with stacked counts */}

          <Bar
            dataKey={'distractedCount'}
            fill='rgb(255, 128, 171)'
            legendType='none'
            barSize={12}
            onMouseOver={() => {
              setDataKeyTask('distractedCount')
            }}
            name={`Distracted Count`}
          />
          <Bar
            dataKey={'understandCount'}
            fill='rgb(234, 128, 252)'
            legendType='none'
            barSize={12}
            onMouseOver={() => {
              setDataKeyTask('understandCount')
            }}
            name={`Understand Count`}
          />
          <Bar
            dataKey={'foreignCount'}
            fill='rgb(179, 136, 255)'
            legendType='none'
            barSize={12}
            onMouseOver={() => {
              setDataKeyTask('foreignCount')
            }}
            name={`Foreign Count`}
          />
{/* Line component */}
{/* <Line
      type="monotone"
      dataKey="maxNoAnswerCount" // Ensure correct data key
      stroke="#279198" // Change stroke color for better visibility
      strokeWidth={3} // Increase stroke width for better visibilit
    /> */}
        {/* </BarChart> */}
        </ComposedChart>
      </ResponsiveContainer>
      <CustomLegend />
    </Box>
  )
}

export default ChildNonParticipationPerAge
