import { Department } from './department'

export default interface Preschool {
  id: string
  name: string
  areaId: string
  areaName?: string
  numberOfUsers?: number
  organizationId?: string
  municipalityId?: string
  preschoolName?: string
  preschoolId?: string
  departments?: Department[]
  departmentId?: string
}

export interface PreschoolShape {
  name: string
  areaId: string
  municipalityId: string
}

export const defaultPreschoolShape: PreschoolShape = {
  name: '',
  areaId: '',
  municipalityId: '',
}

export const emptyPreschool: Preschool = {
  id: '',
  areaId: '',
  name: '',
  areaName: '',
  departments: [],
  municipalityId: '',
  numberOfUsers: 0,
  organizationId: '',
  preschoolId: '',
  preschoolName: '',
}

export interface PreschoolStatistics {
  id: number
  preschoolName: string
  areaName: string
  submittedWeekCount: number
  submittedTotalCount: number
  participant: number
}

export interface OverviewStatistics {
  organizationId: string
  followupId: string
  areaId: string
  preschoolId: string
  numberOfSchools: number
  submittedTotalCount: number
  submittedWeekCount: number
  numberOfParticipants: number
  needsUpdate: boolean
}

export interface ResultsStatistics {
  organizationId: string
  numberOfSchools: number
  numberOfFollowups: number
  submittedTotalCount: number
  numberOfParticipants: number
}

export enum StatisticsCategories {
  findPatterns = 'Hitta mönster',
  reasoning = 'Förklara',
  imageScore = 'Resonera',
  expressOpinions = 'Föra fram åsikter',
  expressesOwnPerspective = 'Uttrycker sitt eget perspektiv',
  cooperation = 'Samarbetar med andra',
  interactSocializeWithOthers = 'Samspela med andra - Umgås med andra',
  expressesInnerImages = 'Uttrycker sina inre bilder',
  expressesInnerImagesSaga = 'Uttrycker sina inre bilder (saga)',
  expressesInnerImagesFace = 'Uttrycker sina inre bilder (ansikte)',
  expressesInnerImagesSandCastles = 'Uttrycker sina inre bilder (sandslott)',
  understandsOthersExpressesEmotions = 'Förstå andras perspektiv - Uttrycka och reglera känslor',
  understandsOthersExpressesEmotionsA = 'A. Förstå andras perspektiv - Uttrycka och reglera känslor',
  understandsOthersExpressesEmotionsB = 'B. Förstå andras perspektiv - Uttrycka och reglera känslor',
  independentChoice = 'Självständiga val',
  averagePercentForOrg = 'Genomsnitt för organisationen',
  averagePercent = 'Genomsnittlig procent',
}
export enum StatisticsCategoriesEn {
  findPatterns = 'Find Patterns',
  reasoning = 'Explain',
  imageScore = 'Reason',
  expressOpinions = 'Present opinions',
  expressesOwnPerspective = 'Expresses own perspective',
  cooperation = 'Cooperates with others',
  interactSocializeWithOthers = 'Interact with others - Socialize with others',
  expressesInnerImages = 'Expresses its inner images',
  expressesInnerImagesSaga = 'Expresses its inner images (saga)',
  expressesInnerImagesFace = 'Expresses its inner images (face)',
  expressesInnerImagesSandCastles = 'Expresses its inner images (sandcastles)',
  understandsOthersExpressesEmotions = 'Understanding others perspectives - Expressing and regulating emotions',
  understandsOthersExpressesEmotionsA = 'A. Understanding others perspectives - Expressing and regulating emotions',
  understandsOthersExpressesEmotionsB = 'B. Understanding others perspectives - Expressing and regulating emotions',
  independentChoice = 'Independent elections',
  averagePercentForOrg = 'Average for the organization',
  averagePercent = 'Average percentage',
}

export enum StatisticsCategoriesDe {
  findPatterns = 'Muster erkennen',
  reasoning = 'Erklären',
  imageScore = 'Begründen',
  expressOpinions = 'Meinungen äußern',
  expressesOwnPerspective = 'Eigene Perspektive äußern',
  cooperation = 'Mit anderen kooperieren',
  interactSocializeWithOthers = 'Mit anderen interagieren - Sozialisieren',
  expressesInnerImages = 'Innere Bilder ausdrücken',
  expressesInnerImagesSaga = 'Innere Bilder ausdrücken (Saga)',
  expressesInnerImagesFace = 'Innere Bilder ausdrücken (Gesicht)',
  expressesInnerImagesSandCastles = 'Innere Bilder ausdrücken (Sandburgen)',
  understandsOthersExpressesEmotions = 'Andere Perspektiven verstehen - Emotionen ausdrücken und regulieren',
  understandsOthersExpressesEmotionsA = 'A. Andere Perspektiven verstehen - Emotionen ausdrücken und regulieren',
  understandsOthersExpressesEmotionsB = 'B. Andere Perspektiven verstehen - Emotionen ausdrücken und regulieren',
  independentChoice = 'Unabhängige Entscheidungen',
  averagePercentForOrg = 'Durchschnitt für die Organisation',
  averagePercent = 'Durchschnittlicher Prozentsatz',
}


export const IndicatorColors: Record<
  keyof typeof StatisticsCategories | 'childrenResult',
  string
> = {
  findPatterns: '#E2B4E5',
  imageScore: '#D6C566',
  expressOpinions: '#EAC051',
  cooperation: '#CDA376',
  reasoning: '#F5A984',
  // POJKE: "#86E6FC",
  // FLICKA: "#FD9FC1",
  // StaffTurnover: "#515151",
  // TeacherSurvey: "#C20B9A",
  // ParentsWithHigherEducation: "#986B27",
  // ForeignBackground: "#5831C6",
  // fundingPerChild: "#646c77",
  childrenResult: '#279198',
  // proportionOfBoys: "#dab3da", // Need to change color
  // pedagogicalExam: "#967abc", // Need to change color
  // sickLeave: "#FF1E00", // Need to change color
  // meanAboveMedian: "#D08C64",
  // meanBelowMedian: "#894C28",
  // age: "#b4aaa1",
  // gender: "#dbc558",
  // numberOfChildren: "#ffa057",
  expressesOwnPerspective: '#D1D367',
  expressesInnerImagesSaga: '#849433',
  expressesInnerImagesFace: '#9F8193',
  expressesInnerImagesSandCastles: '#A6EF76',
  independentChoice: '#D0C5B4',
  expressesInnerImages: '#AAB59B',
  // scoreBelow15hrs: "#BE2929",
  // scoreAbove15hrs: "#008537",
  // actualWeeklyPresence: "#646c77",
  // result: "#279198",
  interactSocializeWithOthers: '#b4aaa1',
  understandsOthersExpressesEmotions: '#dbc558',
  understandsOthersExpressesEmotionsA: '#dbc558',
  understandsOthersExpressesEmotionsB: '#a89224',
  averagePercent: '#EC2215',
  averagePercentForOrg: '#EC2215',
}

//You can only have a maximum of 6 tasks in a followup.
export const BarChartColors = [
  '#AFEAC4',
  '#FFE64E',
  '#DE979E',
  '#B7D0F3',
  '#FFCC8D',
  '#92684A',
]

export const IndicatorColorsConcated = [
  '#CDA376',
  '#C20B9A',
  '#D08C64',
  '#9F8193',
  '#849433',
  '#A6EF76',
  '#D1D367',
  '#AAB59B',
  '#E2B4E5',
  '#D0C5B4',
  '#B4AAA1',
  '#F5A984',
  '#986B27',
  '#5831C6',
  '#515151',
  '#DBC558',
  '#A89224',
  '#92684A',
  '#FFCC8D',
  '#B7D0F3',
  '#DE979E',
  '#FFE64E',
  '#AFEAC4',
  '#EC2215',
  '#D6C566',
]

export type AveragePerAgeStatistics = {
  scores: {
    taskId: string
    categoryId: string
    average: number
    answerCount: number
    noAnswerCount: number
   
  }[]
} & {
  age: number
  distractedCount?:number
  foreignCount?:number
  understandCount?:number
  nonParticipationReasonCount?:number
}

interface Task {
  taskId: string;
  wordsPerMinAverage: number;
  totalWords: number;
  completeSentence: number;
  speakingTime: number;
}

export type  LanguageAnalysisStatisticsType = {
  age: number;
  tasks: Task[];
}


export type AveragePerAgeHorizonStatistics = {
  [stat1 in keyof typeof StatisticsCategories]: number
} & {
  age: number
  average: number
  participantAverage: number
}

export type AveragePerGenderStatistics = {
  male: number
  female: number
  categoryId: keyof typeof StatisticsCategories
  taskId: string
}

export type AveragePerSchoolAgeStatistics = {
  preschoolId: number
  preschoolName: string
  age: number
  average: number
  participantAverage: number
  totalCount: number
}
export const StatsToTitleDictionary: Record<string, string> = {
  'b4d26945-4c02-4089-a2b5-1bea3c08018c_Cooperation':
    'Samarbetar med andra (äpple)',
  'b943764b-d3da-406e-b841-8c5b553250af_Cooperation':
    'Samarbetar med andra (bilder)',
  'd1340363-f10c-48d6-8895-de53a4753e53_Cooperation':
    'Samarbetar med andra (dragkamp)',
  '88029335-3eee-4a20-9a08-74d2d60d96a0_ExpressesInnerImagesFace':
    'Uttrycker sina inre bilder (ansikte)',
  'dd683d82-5bfb-4584-892c-e2ef4aa5def3_ExpressesInnerImagesSaga':
    'Uttrycker sina inre bilder (saga)',
  '8d4dda81-a4de-4435-a8cc-43a9888c44c6_ExpressesInnerImagesSandCastles':
    'Uttrycker sina inre bilder (sandslott)',
  '09599b2d-a630-4b41-bdc4-4209bc955a5d_ExpressesOwnPerspective':
    'Uttrycker sitt eget perspektiv',
  'b21f4d6f-33ec-2414-a935-3122b50a5eeb_ExpressOpinions':
    'Föra fram åsikter och tankar',
  '1f304633-c12c-846e-6695-e53de53a4753_FindPatterns': 'Hitta mönstret',
  '2bf15e4d-40fd-4412-a962-2213a50a5edf_IndependentChoice': 'Självständigt val',
  '98161640-165d-4a32-97e7-9f03f4662e01_InteractSocializeWithOthers':
    'Samspela med andra - Umgås med andra',
  'f3016433-12ee-84fd-6690-a53de53c4752_Reasoning': 'Förklara',
  '95c0ef47-fab1-468d-bbdf-2c279557ac2b_Reasoning': 'Resonera (storlek djur)',
  '4ce43f34-093e-4957-a4e6-40bb6cd105dd_Reasoning': 'Resonera (storlek hus)',
  'fe921843-4921-4227-8306-0c1065054646_Reasoning':
    'Resonera (daglig aktivitet)',
  '0d85244e-820a-4033-a35f-a6edb3247a7b_UnderstandsOthersExpressesEmotionsA':
    'A. Förstå andras perspektiv - Uttrycka och reglera känslor',
  '0d85244e-820a-4033-a35f-a6edb3247a7b_UnderstandsOthersExpressesEmotionsB':
    'B. Förstå andras perspektiv - Uttrycka och reglera känslor',
}

export const ProgressionFollowupColors = [
  '#CBCD66',
  '#FFBC8B',
  '#987687',
  '#6A8ED1',
]
export interface PreschoolDTO {
  id: string
  name: string
  areaId: string
  municipalityId: string
  organizationId: string
}

export type CreatePreschoolDTO = Partial<Omit<PreschoolDTO, 'id'>>
export type EditPreschoolDTO = Partial<Omit<PreschoolDTO, 'organizationId'>>

export interface PreschoolList {
  numberOfPreschools: number
  numberOfUsers: number
  preschools: Preschool[]
}
